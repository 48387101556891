export default (data = {}) => {
    return {
        store_id: null,
        issue_date: null,
        trout_qty: 1000,
        trout_len: 15,
        trout_qty_by_kg: 30,
        water_temp: 15,
        biomasa: 0,
        ...data
    }
}