<template>
  <v-container fill-height align="center">
    <v-row justify="center">
      <v-btn @click="calculator_dialog=!calculator_dialog">Calculadora</v-btn>
      <trout_calculator
        :dialog="calculator_dialog"
        @close="calculator_dialog = false"
      />
    </v-row>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import trout_calculator from "../components/Trout_calculator.vue";
export default {
  components: { trout_calculator },
  data: () => ({
    table: "stores",
    calculator_dialog: false,
  }),
  methods: {
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.items_bygroup = data.reduce(function (r, a) {
          r[a.group_name] = r[a.group_name] || [];
          r[a.group_name].push(a);
          return r;
        }, Object.create(null));

        this.groups = Object.keys(this.items_bygroup).sort();

        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
  },
  mounted() {
    this.get_items();
  },
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>