<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card tile>
        <v-card-title class="pa-0">
          <v-app-bar>
            <v-app-bar-title>Calculadora</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn x-small color="info" fab @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                v-model="item.store_id"
                ref="store_id"
                :error="errors.store_id"
                :items="storeLst"
                item-text="name"
                item-value="code"
                label="Piscina"
                outlined
                dense
                hide-details="auto"
                :rules="f_required"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="item.trout_qty"
                label="Cantidad de truchas"
                hide-details="auto"
                dense
                outlined
                type="number"
                @change="calculator"
                max="40000"
                min="3000"
              />
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                v-model="item.trout_qty_by_kg"
                label="Cantidad x (2.2 libras ó 1000 gr)"
                hide-details="auto"
                dense
                outlined
                type="number"
                @change="calculator"
                max="669"
                min="2"
              />
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                v-model="item.trout_len"
                label="Longitud en cm."
                hide-details="auto"
                dense
                outlined
                type="number"
                @change="calculator_l"
                max="26"
                min="2"
              />
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                v-model="item.water_temp"
                label="Temperatura del agua"
                hide-details="auto"
                dense
                outlined
                type="number"
                @change="calculator"
                max="19"
                min="8"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-card dark color="info" width="100%">
              <v-card-title>{{ this.item.store_id }}</v-card-title>
              <v-card-text>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Cantidad de truchas</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ this.resume.trout_qty }}
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Peso promedio</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ this.resume.weight_average + " gr" }}
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Biomasa</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ this.resume.biomasa + " gr" }}
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Cantidad alimento diario</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    {{
                      this.resume.feed_day +
                      " X " +
                      this.resume.dosis +
                      " gr" +
                      " = " +
                      this.resume.food_day
                    }}
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-app-bar>
            <v-spacer></v-spacer>
            <v-btn large text color="info" @click="calculator()">
              Calcular
            </v-btn>
          </v-app-bar>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import trout_set from "../utils/trout_set.js";
export default {
  name: "trout_calculator",
  components: {},
  data() {
    return {
      storeLst: [],
      trout_table: [],
      resume: {},
      password_value: String,
      loading_status: false,
      tab: null,
      errors: trout_set(),
      item: trout_set(),
      dialog_confirm: false,
      compressed_image: null,
      reg_delete: null,
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
    };
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    this.get_stores();
    this.get_trout_set();
  },
  methods: {
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, src) {
      var url = "https://sw67.ca/photos/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_image = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    item_submit(e) {
      e.preventDefault();
    },
    close() {
      this.$emit("close", true);
    },
    get_stores() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "stores",
        filters: [],
      };
      webserver("get_table", qry, (data) => {
        this.storeLst = data;
      });
    },
    get_trout_set() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "trout_set",
        filters: [],
      };

      webserver("get_table", qry, (data) => {
        data.forEach((itm) => {
          itm.qty_to = parseFloat(itm.qty_to);
          itm.qty_from = parseFloat(itm.qty_from);
          itm.feed_factor = parseFloat(itm.feed_factor);
          itm.trout_qty_by_kg = parseFloat(itm.trout_qty_by_kg);
          itm.len_from = parseFloat(itm.len_from);
          itm.len_to = parseFloat(itm.len_to);
        });
        this.trout_table = data;
        this.calculator();
      });
    },
    calculator() {
      this.resume = {};
      var dta = parseFloat(this.item.trout_qty_by_kg);
      var cantidad_truchas = parseFloat(this.item.trout_qty);
      var peso_promedio = parseFloat(1000 / dta);
      var biomasa = cantidad_truchas * peso_promedio;
      var water_temp = parseFloat(this.item.water_temp);

      var qty_to_lst = this.trout_table.filter(function (item) {
        return item.qty_to > dta;
      });
      var resp = qty_to_lst.filter(function (item) {
        return item.qty_from < dta;
      });
      var response = resp.filter(function (item) {
        return item.temp == water_temp;
      });

      var calcu = response[0];
      var factor = parseFloat(calcu.feed_factor);
      this.item.trout_len =
        (calcu.len_to - calcu.len_from) / 2 + calcu.len_from;
      calcu.feed_day=parseFloat(calcu.feed_day);
        console.log(calcu);

      calcu.trout_qty = cantidad_truchas.toFixed(2);
      calcu.weight_average = peso_promedio.toFixed(2);
      calcu.biomasa = biomasa.toFixed(2);
      calcu.food_day = ((parseFloat(biomasa) * parseFloat(factor)) / 100).toFixed(2);
      calcu.dosis = (parseFloat(calcu.food_day) / calcu.feed_day).toFixed(2);
      this.resume = calcu;
    },

    calculator_l() {
      // var dta = parseFloat(this.item.trout_len);
      // var water_temp = parseFloat(this.item.water_temp);
      // var qty_to_lst = this.trout_table.filter(function (item) {
      //   return item.len_to >= dta;
      // });
      // var resp = qty_to_lst.filter(function (item) {
      //   return item.len_from < dta;
      // });
      // this.response = resp.filter(function (item) {
      //   return item.temp == water_temp;
      // });
      // var calcu = this.response[0];
      // console.log(calcu);
      // this.item.trout_qty_by_kg =
      //   (calcu.qty_to - calcu.qty_from) / 2 + calcu.qty_from;
      // calcu.trout_qty = peso_promedio;
      // calcu.weight_average = peso_promedio.toFixed(2);
      // calcu.biomasa = (calcu.trout_qty * calcu.weight_average).toFixed(2);
      // calcu.food_day = ((calcu.biomasa * calcu.feed_factor) / 100).toFixed(2);
      // calcu.dosis = (calcu.food_day / calcu.feed_day).toFixed(2);
      // console.log(calcu);
      // this.response = calcu;
    },
  },
};
</script>

<style>
.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>